@import '../../assets/styles/index.module.scss';

.hero {
  background: url('../../assets/images/home_hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  display: flex;
  align-items: center;

  &_inner {
    @include center-section-wrapper;
    width: 100%;
  }
}

.header {
  font-family: 'Permanent Marker';
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  color: $primary-color;
  margin-bottom: 25px;
}

.subheader {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 50px;
  max-width: 450px;
}

.history {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 500px;
  @include padding-vertical(75px);
  @include center-section-wrapper;
  @include breakpoint-up(lg) {
    flex-direction: row;
  }

  &_img {
    min-width: 330px;
    max-width: 100%;
  }

  &_text {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    @include breakpoint-up(lg) {
      width: 50%;
      text-align: left;
      margin-top: 0px;
    }
  }

  &_title {
    font-family: 'Permanent Marker';
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: $primary-color;
    margin-top: 0;
  }

  &_highlighted {
    font-family: 'Permanent Marker';
    font-weight: bold;
    margin-top: 30px;
    color: $primary-color;
  }
}

.title {
  border-top: 2px solid $primary-color;;
  border-bottom: 2px solid $primary-color;;
  padding: 20px 0;
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
}

.partner {
  text-align: center;
  margin-bottom: 50px;
  &>h3 {
    font-family: 'Permanent Marker';
    color: $primary-color;
    font-size: 18px;
    line-height: 24px;
  }
}

.products {
  @include center-section-wrapper;
  padding-bottom: 150px;

  &_header {
    text-align: center;
    padding-bottom: 50px;
    &>h2 {
      margin-bottom: 0px;
    }
  }

  & h3 {
    font-family: 'Permanent Marker';
    color: $primary-color;
    font-size: 20px;
    line-height: 28px;
  }

  &_slogan {
    font-weight: bold;
  }

  &_section1,
  &_section2,
  &_section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &_section1,
  &_section3 {

    flex-direction: column-reverse;

    @include breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &_box {
    width: 300px;
    max-width: 360px;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    border-radius: 4px;

    @include breakpoint-up(md) {
      min-width: 360px;
    }
    @include breakpoint-up(lg) {
      height: 275px;
    }
  }

  & a {
    color: $primary-color;
    text-decoration: none;
  }

  &_boxend {
    margin: 15px auto;
    padding: 20px 20px 50px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: auto;
    max-width: 790px;
    text-align: center;

    &_link {
      color: $primary-color;
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
      border: 1px solid;
      padding: 8px;
      border-radius: 4px;
    }
  }


}