@import '../../../assets/styles/index.module.scss';

.background {
  background: url('../../../assets/images/footer_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 175px;
  @include center-section-wrapper;
  padding-top: 125px;
  padding-bottom: 40px;
}

.divider {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  border: none;
  margin: 0;
}

.company {

  width: 100%;
  text-align: center;
  padding-bottom: 20px;

  &>img {
    margin: -10px auto 0;
  }

  &_description {
    max-width: 400px;
    margin: 15px auto 15px;
  }
}

.social {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0px;
  padding-bottom: 30px;
  margin-block-start: 0px;
  margin-block-end: 0px;

  &_item {
    @include padding-horizontal(10px);
  }
}

.legal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  list-style: none;
  width: 300px;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: -20px;
  @include font-open-sans(14px, 22px, regular);

  &_item {
    @include padding-horizontal(10px);

    &:first-of-type {
      display: none;
    }
  }
}

.social_link,
.legal_link {
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

.creatorInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding-top: 1px;   
  @include font-open-sans(14px, 22px, regular);
  @include center-section-wrapper();

  &_logo {
    font-family: 'Quicksand', sans-serif; 
    font-weight: 700;
    color: $primary-color;
    text-decoration: none;
  }
  
  &_icon {
    color: #CF222D;
    padding: 4px 0 0;
  }
}

.copyright {
  padding-top: 5px;
  @include breakpoint-up(lg) {
    padding-top: 0px;
  }
  &_icon {
    font-size: 12px;
  }
}

