@import '../../assets/styles/index.module.scss';

.general {
  text-decoration: none;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

// Type Styles
.primary {
  background-color: $primary-color;
  color: $white;
  border: 1px solid $primary-color;
  &:hover {
    background-color: lighten($primary-color, 5%)
  }
}

.secondary {
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  &:hover {
    background-color: $primary-color;
    color: $white;
  }
}

// Size Styles
.small {
  @include font-open-sans(14px, 22px, regular);
  padding: 2px 10px;
}

.medium {
  padding: 4px 20px;
}

.large {
  padding: 7px 20px;
}


// Button Shape Styles
.regular {
  border-radius: 4px;
}

.rounded {
  border-radius: 99px;
}

.square {
  border-radius: 0px;
}

