@import '../../assets/styles/index.module.scss';

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @include padding-vertical(100px,50px);
  @include center-section-wrapper;
}

.product {
  background: white;
  max-width: 275px;
  border-radius: 4px;
  margin: 15px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  border: 1px solid $divider-color;

  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
  }

  &>h3 {
    font-family: "Permanent Marker";
    font-size: 24px;
    line-height: 32px;
    color: $primary-color;
    border-top: 1px solid;
    padding-top: 30px;
    margin-bottom: 0;
  }

  &>img {
    max-width: 100%;
    height: 200px;
  }
}

.title {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  padding-top: 150px;
  max-width: 600px;
  margin: 0 auto;
}