@import '../../assets/styles/index.module.scss';

.background {
  background: url('../../assets/images/header_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  @include center-section-wrapper;

  &_active {
    @extend .wrapper;
    background: white;
  }
}

.logo {
  text-decoration: none;
  height: fit-content;
}

.navLink {

  @include font-open-sans(16px, 24px, semibold);
  @include padding-horizontal(20px);
  text-decoration: none;
  color: $secondary-color;

  &:last-of-type {
    padding-right: 0px;
  }

  &:visited {
    text-decoration: none;
    color: $secondary-color;
  }
  
  &:hover {
    color: $primary-color;
  }

  &_active {
    color: $primary-color;
  }

}

.button {
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
}

.menuBars{

  @include size(66px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -20px;

  &_icon {
    font-size: 30px;
    line-height: 36px;
    color: $icon-color;
  }
}