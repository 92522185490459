@import '../../assets/styles/index.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 20px auto 0;
  padding: 30px;
  background: $white;
  border: 1px solid $divider-color;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);

  @include breakpoint-up(md) {
    padding: 50px;
  }

  &>h2 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

.input {
  height: 40px;
  margin-bottom: 20px;
  padding: 0 0 0 10px; 
  border-radius: 4px;
  border: 1px solid $divider-color;
  background: #f2f2f2;
  @include font-open-sans(14px, 22px, regular);

  &:hover {
    border: 1px solid $primary-color;
  }

  &::placeholder {
    color: $placeholder-color;
  }

}

.textarea {
  @extend .input;
  height: 100px;
  padding: 10px 0 0 10px; 
  resize: vertical;
}

.legal {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  &_checkbox {
    height: 18px;
    min-width: 18px;
    margin-right: 15px;
    margin-top: 0px;
  }

  &_text {
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }
}