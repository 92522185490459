@import '../../assets/styles/index.module.scss';

.hero {
  background: url('../../assets/images/member_hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  display: flex;
  align-items: center;

  &_inner {
    @include center-section-wrapper;
    width: 100%;
  }
}

.header {
  font-family: 'Permanent Marker';
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  color: $primary-color;
  margin-bottom: 25px;
}

.subheader {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 50px;
  max-width: 450px;
}

.benefits {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 500px;
  @include padding-vertical(75px, 50px);
  @include center-section-wrapper;
  @include breakpoint-up(lg) {
    flex-direction: row;
  }

  &_img {
    min-width: 330px;
    max-width: 100%;
  }

  &_text {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    @include breakpoint-up(lg) {
      width: 50%;
      text-align: left;
      margin-top: 0px;
    }
  }

  &_title {
    font-family: 'Permanent Marker';
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: $primary-color;
    margin-top: 0;
  }

  &_highlighted {
    font-family: 'Permanent Marker';
    font-weight: bold;
    margin-top: 30px;
    color: $primary-color;
  }
}

.test {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  min-height: 500px;
  @include padding-vertical(50px, 75px);
  @include center-section-wrapper;
  @include breakpoint-up(lg) {
    flex-direction: row;
  }

  &_img {
    min-width: 330px;
    max-width: 100%;
  }

  &_text {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    @include breakpoint-up(lg) {
      width: 50%;
      text-align: left;
      margin-top: 0px;
    }
  }

  &_title {
    font-family: 'Permanent Marker';
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: $primary-color;
    margin-top: 0;
  }

  &_highlighted {
    font-family: 'Permanent Marker';
    font-weight: bold;
    margin-top: 30px;
    color: $primary-color;
  }
}