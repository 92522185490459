// General Variables

$max-page-width: 1100px;
$page-padding-top: 66px;

// Font weights

$font-weights-openSans: (
  light: 300,
  regular: normal,
  medium: 500,
  semibold: 600,
  bold: bold,
);

// Breakpoints

$grid-breakpoint-xs: 0;
// Small devices (landscape phones, 576px and up)
$grid-breakpoint-sm: 576px;
// Medium devices (tablets, 768px and up)
$grid-breakpoint-md: 768px;
// Large devices (desktops, 992px and up)
$grid-breakpoint-lg: 992px;
// Extra large devices (large desktops, 1200px and up)
$grid-breakpoint-xl: 1200px;

$grid-breakpoints: (
  xs: $grid-breakpoint-xs,
  sm: $grid-breakpoint-sm,
  md: $grid-breakpoint-md,
  lg: $grid-breakpoint-lg,
  xl: $grid-breakpoint-xl
);

// Page Paddings

$page-paddings: (
  xs: 20px,
  sm: 30px,
  md: 30px,
  lg: 40px,
  xl: 40px
);
