@import 'assets/styles/index.module.scss';

.mobile, .small, .medium, .large {
  font-family: 'Quicksand', sans-serif; 
  font-weight: 700;
}

.white {
  color: $white;
}

.primary {
  color: $primary-color;
}

.mobile {
  font-size: 24px;
  line-height: 32px;
}

.small {
  font-size: 24px;
  line-height: 32px;
}

.medium {
  display: flex;
  height: 44px;
}

.large {
  font-size: 48px;
  line-height: 60px;
}