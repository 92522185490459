@import '../../../assets/styles/index.module.scss';

.background {
  background-color: $secondary-color;
  color: white;
  border-top: 1px solid $divider-color;

  & a {
    color: white;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 175px;
  @include center-section-wrapper;
  @include padding-vertical(40px);

  @include breakpoint-up(sm) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  @include breakpoint-up(lg) {
    justify-content: space-between;
    text-align: left;
  }
}

.divider {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  border: none;
  margin: 0;
}

.company {
  width: 100%;
  @include padding-vertical(15px, 20px);
  @include font-open-sans(14px, 23px, regular);
  text-align: center;

  &>img {
    margin: 0 auto;
  }

  @include breakpoint-up(lg) {
    width: 25%;
    text-align: left;
    padding-bottom: 0px;
    &>img {
      margin: 0;
    }
  }

  &_description {
    max-width: 400px;
    margin: 15px auto 15px;
  }
}

.section {
  list-style: none;
  padding-inline-start: 0px;
  margin-block-start: 15px;

  @include breakpoint-between(sm, lg) {
    width: 30%;
    padding: 10px;
  }
}

.section_item {
  @include font-open-sans(14px, 23px, regular);
  @include padding-horizontal(20px);
  padding-bottom: 5px;

  &:first-of-type {
    padding-bottom: 15px;
    @include font-open-sans(16px, 24px, semibold);
    color: $primary-color;
  }

  @include breakpoint-up(lg) {
    @include padding-horizontal(0px);
  }
}

.section_link {
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

.creatorInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding-top: 1px;   
  @include font-open-sans(14px, 22px, regular);
  @include center-section-wrapper();

  @include breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
    min-height: 50px;
  }

  &_logo {
    font-family: 'Quicksand', sans-serif; 
    font-weight: 700;
    color: $primary-color;
    text-decoration: none;
  }
  
  &_icon {
    color: #CF222D;
    padding: 4px 0 0;
  }
}

.copyright {
  padding-top: 5px;
  @include breakpoint-up(lg) {
    padding-top: 0px;
  }
  &_icon {
    font-size: 12px;
    padding-bottom: 1px;
    @include breakpoint-up(lg) {
      padding-bottom: 0px
    }
  }
}
