.map {
  width: 100%;
  max-width: 1100px;
  min-height: 500px;
  margin: 50px auto 100px;
  display: flex;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0,.25);
  border-radius: 4px;
}
