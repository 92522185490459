@import '../../assets/styles/index.module.scss';

.background {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.container {
  @include center-section-wrapper;
  width: 100%;
  min-height: 600px;
  border-radius: 4px;
  text-align: center;

  @include breakpoint-up(lg) {
    @include margin-vertical(30px, 50px);
  }
}

.image {
  max-width: 100%;
}

.header {
  text-align: center;
  padding: 50px 0 25px;

  &>h1 {
    font-family: 'Permanent Marker';
    font-size: 48px;
    line-height: 56px;
  }

  &>p {
    @include font-open-sans(16px, 24px, semibold);
    max-width: 500px;
    margin: 0 auto;
  }
}

.hours {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  background: white;
  width: 220px;
  border-radius: 4px;
  padding: 10px;
  margin: 15px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  border: 1px solid $divider-color;

  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
  }
}

.day {
  font-family: 'Permanent Marker';
  color: $primary-color;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
}