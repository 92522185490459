@import './variables.module.scss';


@mixin font-open-sans($size: false, $lh: false, $weight: false ) {
  font-family: 'Open Sans', sans-serif;
  @if $size { font-size: $size; }
  @if $lh { line-height: $lh; }
  @if $weight { font-weight: map-get($font-weights-openSans, $weight); }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin circle($radius: null) {
  @if($radius == null){}
  @else{
    @include size($radius);
  }
  border-radius: 50%;
}

@mixin center-section-wrapper(){
  margin: 0 auto;
  max-width: $max-page-width;
  @media (min-width: map-get($grid-breakpoints, 'xs')) { 
    padding-left: map-get($page-paddings, 'xs');
    padding-right: map-get($page-paddings, 'xs');
  }
  @media (min-width: map-get($grid-breakpoints, 'sm')) { 
    padding-left: map-get($page-paddings, 'sm');
    padding-right: map-get($page-paddings, 'sm');
  }
  @media (min-width: map-get($grid-breakpoints, 'md')) { 
    padding-left: map-get($page-paddings, 'md');
    padding-right: map-get($page-paddings, 'md');
  }
  @media (min-width: map-get($grid-breakpoints, 'lg')) { 
    padding-left: map-get($page-paddings, 'lg');
    padding-right: map-get($page-paddings, 'lg');
  }
  @media (min-width: map-get($grid-breakpoints, 'xl')) { 
    padding-left: map-get($page-paddings, 'xl');
    padding-right: map-get($page-paddings, 'xl');
  }
}

@mixin padding-vertical($top, $bottom: $top){
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin padding-horizontal($left, $right: $left){
  padding-left: $left;
  padding-right: $right;
}

@mixin margin-vertical($top, $bottom: $top){
  margin-top: $top;
  margin-bottom: $bottom;
}

@mixin margin-horizontal($left, $right: $left){
  margin-left: $left;
  margin-right: $right;
}

@mixin breakpoint-up($breakpoint) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) { @content; }
}

@mixin breakpoint-between($breakpoint1, $breakpoint2) {
  @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint1)) and (max-width: map-get($grid-breakpoints, $breakpoint2)) { 
    @content; 
  }
}
