@import '../../assets/styles/index.module.scss';

.wrapper {
  @include center-section-wrapper;
  @include padding-vertical(60px);
  overflow-wrap: break-word;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $divider-color;
  border-bottom: 1px solid $divider-color;
  padding: 20px 0;
  height: 100px;
}

.header {
  margin: 0;
}

.update {
  font-size: 14px;
  margin: 5px 0 0;
}

.image_container,
.image {
  height: 120px;
}

.image {
  display: none;
  @include breakpoint-up(sm) {
    display: inline;
    padding-left: 20px;
  }
}

.content {
  & h2, h3 {
    padding-top: 30px;
  }
}